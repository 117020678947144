var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "id-popup-quick-input-multiple-pax",
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "header-bg-variant": "light-info",
      "footer-class": "justify-content-between",
      "title-class": _vm.isMobileView ? 'h4 header-class text-dark' : 'h2 header-class text-dark',
      "title": _vm.$t('flight.quickInputPaxInfo'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel'),
      "ok-disabled": !_vm.validInput
    },
    on: {
      "ok": _vm.parsePaxs
    }
  }, [_vm.isCreateGroupBooking ? _c('AlertGroupBooking') : _vm._e(), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('flight.quickInputPaxInfoNote'))
    }
  }), _c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.quickInputPaxInfoNoteQuantity')) + " ")]), _c('p', {
    staticClass: "font-weight-bolder ml-md-1",
    class: _vm.isMobileView ? 'font-small-4' : 'font-medium-2'
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.maxAdults) + " " + _vm._s(_vm.$tc('flight.adultPlural', _vm.maxAdults)) + " "), _vm.maxChildren ? [_vm._v(" - " + _vm._s(_vm.maxChildren) + " " + _vm._s(_vm.$tc('flight.childPlural', _vm.maxChildren)) + " ")] : _vm._e(), _vm.maxInfants ? [_vm._v(" - " + _vm._s(_vm.maxInfants) + " " + _vm._s(_vm.$tc('flight.infantdPlural', _vm.maxInfants)) + " ")] : _vm._e()], 2)]), _c('b-form-textarea', {
    staticClass: "text-uppercase",
    attrs: {
      "rows": "6"
    },
    model: {
      value: _vm.paxs,
      callback: function callback($$v) {
        _vm.paxs = $$v;
      },
      expression: "paxs"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }